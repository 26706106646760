import React from "react"
import ServiceDash from "../../components/service-dash"
import shuffle from "../../functions/shuffle"

const GateCrash = () => {
  let template = new ServiceDash()

  let gateCrashes = [
    "Conoco Inc",
    "Deluxe Corporation",
    "Comerica Inc",
    "Delphi Corp",
    "Burlington Northern",
    "CMS Energy Corp",
    "Costco Wholesale Corp",
    "D&K Healthcare",
    "GMGI Inc",
    "Sundance Design Systems",
    "The Pokémon Company",
  ]

  shuffle(gateCrashes)

  template.serviceType = "GateCrash"
  template.serviceArray = gateCrashes

  template.uiAnimateLines()

  return template
}

export default GateCrash